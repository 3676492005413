import {
    Box,
    Center,
    ChevronDownIcon,
    ChevronUpIcon,
    HStack,
    Popover,
    Pressable,
    Radio,
    Text,
    VStack,
} from 'native-base';
import React, { FC, useState } from 'react';

export type Option = {
    label: string;
    value: string;
};

interface SelectButtonProps {
    initialValue: string;
    isLoading?: boolean;
    label: string;
    onChange: (optionValue: string) => void;
    options: Option[];
}

const SelectButton: FC<SelectButtonProps> = ({ initialValue, label, onChange, options }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Box w="full">
            <Popover
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                trigger={(triggerProps) => (
                    <Pressable {...triggerProps}>
                        <Center bg="white" borderWidth="1px" rounded="3xl">
                            <HStack
                                alignItems="center"
                                borderStyle="1px"
                                height="40px"
                                justifyContent="space-between"
                                px={4}
                                width="full"
                            >
                                <Text color="black" fontSize="sm">
                                    {label}
                                </Text>
                                {isOpen ? <ChevronUpIcon color="black" /> : <ChevronDownIcon color="black" />}
                            </HStack>
                        </Center>
                    </Pressable>
                )}
            >
                <Popover.Content accessibilityLabel="Select site type" bg="gray.500" px={6}>
                    <Popover.Body px={2} py={4}>
                        <Radio.Group
                            name="selectButton"
                            onChange={(newOption) => {
                                setIsOpen(false);
                                onChange(newOption);
                            }}
                            value={initialValue}
                        >
                            <VStack alignItems="flex-start" space={4}>
                                {options.map((option) => (
                                    <Radio key={option.value} colorScheme="blueGray" fontSize="sm" value={option.value}>
                                        {option.label}
                                    </Radio>
                                ))}
                            </VStack>
                        </Radio.Group>
                    </Popover.Body>
                </Popover.Content>
            </Popover>
        </Box>
    );
};

export default SelectButton;
