import { Heading, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from '../../components/FormModal/FormModal';
import GradientButton from '../../components/GradientButton/GradientButton';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import ExpenseUsersMonthSelection from './ExpenseUsersMonthSelection/ExpenseUsersMonthSelection';

/**
 * Page with tools for managers of the company
 */
const ToolsPage = () => {
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const [expenseMonthSelectionModalOpen, setExpenseMonthSelectionModalOpen] = useState(false);

    return (
        <VStack mx={32} my={20}>
            {company !== undefined ? (
                <FormModal
                    isOpen={expenseMonthSelectionModalOpen}
                    onClose={() => setExpenseMonthSelectionModalOpen(false)}
                    testID="expenses-month-modal"
                    title={t('tools.expenses.title')}
                >
                    <ExpenseUsersMonthSelection
                        closeModal={() => setExpenseMonthSelectionModalOpen(false)}
                        company={company}
                    />
                </FormModal>
            ) : null}
            <PageLayoutHeader title={t('tools.title')} />
            <VStack alignItems="start" space={2}>
                <Heading fontWeight="600" size="md">
                    {t('tools.expenses.title')}
                </Heading>
                <Text mb={2}>{t('tools.expenses.description')}</Text>
                <GradientButton
                    fontSize="sm"
                    label={t('tools.expenses.export')}
                    onClick={() => setExpenseMonthSelectionModalOpen(true)}
                />
            </VStack>
        </VStack>
    );
};

export default ToolsPage;
