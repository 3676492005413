import { Modal } from 'native-base';
import React, { useEffect, useState } from 'react';

interface FormModalProps {
    isOpen: boolean;
    onClose: (isRefreshNeeded: boolean) => void;
    title: string;
    children: React.ReactNode;
    testID: string;
}

const FormModal = ({ isOpen, onClose, title, children, testID }: FormModalProps) => {
    const [key, setKey] = useState<number | undefined>(undefined);

    /**
     * Allows to generate a new key when we open the modal, thus the form is recreated.
     */
    useEffect(() => {
        if (isOpen) {
            setKey(Date.now());
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} testID={testID}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{title}</Modal.Header>
                <React.Fragment key={key}>{children}</React.Fragment>
            </Modal.Content>
        </Modal>
    );
};

export default FormModal;
