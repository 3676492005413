import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';

/* eslint-disable import/no-cycle */
import authReducer, { authSliceName } from './auth/auth';
import loginReducer from './login/login';
import profileReducer from './profile/profile';
/* eslint-enable import/no-cycle */

const rootReducer = combineReducers({ auth: authReducer, login: loginReducer, profile: profileReducer });

const persistConfig: PersistConfig<RootState> = { key: 'root', storage, whitelist: [authSliceName] };
export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
