import { Heading, Spinner, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import SiteCard from '../../components/SiteCard/SiteCard';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { Site } from './types';
import styles from './Sites.module.css';

const SitesPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const company = useAppSelector(companySelect);
    const [sites, setSites] = useState<Site[] | undefined>(undefined);

    useEffect(() => {
        setSites(undefined);
        if (company) {
            providers.sitesProvider.getSites(company.id).then(setSites);
        } else {
            setSites([]);
        }
    }, [company]);

    return (
        <VStack mx={32} my={20}>
            <PageLayoutHeader title={t('sites.title')} />

            {sites !== undefined ? (
                <>
                    <Heading key={2} fontWeight={600} mb={6} mt={12} size="sm">
                        {t('sites.sitesCount', { count: sites.length })}
                    </Heading>
                    <div key={3} className={styles.grid}>
                        {sites.map(({ id, communities, name, type }) => (
                            <SiteCard
                                key={id}
                                communities={communities}
                                name={name}
                                onTouch={() => {
                                    navigate(`/sites/${id}`);
                                }}
                                type={type}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <Spinner size="lg" />
            )}
        </VStack>
    );
};

export default SitesPage;
