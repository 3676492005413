import { ArrowBackIcon, Heading, IconButton, Row, Spacer, Text } from 'native-base';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { getCompany } from '../../store/auth/auth';
import { isGetCompanyPayload } from '../../store/auth/types';
import { useAppDispatch } from '../../store/hooks';
import { getProfileData } from '../../store/profile/profile';
import GradientButton from '../GradientButton/GradientButton';
import Menu from '../Menu/Menu';
import styles from './PageLayout.module.css';

/**
 * Base page layout (including the lateral menu and the main content of the page)
 */
const PageLayout: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [companies, setCompanies] = useState<{ id: string; name: string }[]>([]);

    /**
     * Effect executed when the first Logged-in page is displayed (as the routing is done inside `Outlet`).
     * So, whatever the path the user has taken to be in a Logged-in page (from loggin page or already logged), this effect will have been executed
     */
    useEffect(() => {
        dispatch(getProfileData());
        dispatch(getCompany()).then(({ payload }) => {
            if (isGetCompanyPayload(payload)) setCompanies(payload.companies);
        });
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.menuContainer}>
                <div className={styles.menuContent}>
                    <Menu companies={companies} />
                </div>
            </div>
            <div className={styles.content}>
                <Outlet />
            </div>
        </div>
    );
};

export const PageLayoutHeader = ({
    title,
    backUrl,
    asideButtonTitle,
    asideButtonOnClick,
}: {
    title?: ReactNode;
    backUrl?: string;
    asideButtonTitle?: string;
    asideButtonOnClick?: VoidFunction;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Row alignItems="center" mb={4}>
            {backUrl ? (
                <IconButton
                    icon={<ArrowBackIcon size="6" />}
                    onPress={() => navigate(backUrl)}
                    testID="back-button"
                    variant="unstyled"
                />
            ) : null}
            {title ? (
                <Heading key={1} fontWeight={600} size="lg">
                    {title}
                </Heading>
            ) : (
                <Row alignItems="center">
                    <Text>{t('common.back-to-list')}</Text>
                </Row>
            )}
            <Spacer />
            {asideButtonTitle ? (
                <GradientButton fontSize="sm" label={asideButtonTitle} onClick={asideButtonOnClick} />
            ) : null}
        </Row>
    );
};

export default PageLayout;
