import { Badge, Column, Image, Pressable, Row, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocketChargeStatus } from '../../pages/Sockets/types';
import getChargeStatusLabel from '../../pages/Sockets/utils';
import socket from '../../assets/images/socket.svg';

interface SocketCardProps {
    name: string;
    siteName: string;
    chargeStatus: SocketChargeStatus;
    isConnected: boolean;
    onTouch?: VoidFunction;
}

const getChargeStatusColor = (chargeStatus: SocketChargeStatus, isConnected: boolean) => {
    if (!isConnected) {
        return 'error.600';
    }
    switch (chargeStatus) {
        case SocketChargeStatus.CHARGING:
            return 'primary.600';
        case SocketChargeStatus.NOT_CHARGING:
        default:
            return 'teal.500';
    }
};

const SocketCard = ({ name, siteName, chargeStatus, isConnected, onTouch }: SocketCardProps) => {
    const { t } = useTranslation();

    return (
        <Pressable
            _hover={{ bg: 'secondary.800' }}
            bg="secondary.900"
            borderRadius={10}
            disabled={onTouch === undefined}
        >
            <div onClick={onTouch} onKeyPress={onTouch} role="gridcell" tabIndex={-1}>
                <Column m={3}>
                    <Row alignItems="center" justifyContent="end" space={2}>
                        <Badge bg={getChargeStatusColor(chargeStatus, isConnected)} px={5} py={1}>
                            {getChargeStatusLabel(t, chargeStatus, isConnected)}
                        </Badge>
                    </Row>
                    <Row alignItems="center" mb={3} ml={3}>
                        <Image alt="Socket" size={16} src={socket} />
                        <Column pl={3}>
                            <Text fontSize="sm" fontWeight="600">
                                {name}
                            </Text>
                            <Text fontSize="xs">{siteName}</Text>
                        </Column>
                    </Row>
                </Column>
            </div>
        </Pressable>
    );
};

export default SocketCard;
