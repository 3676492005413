export interface Site {
    id: string;
    name: string;
    type: SiteType;
    communities: string[];
}

export enum SiteType {
    PRIVATE = 'PRIVATE',
    SHARED = 'SHARED',
}
