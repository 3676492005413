import { UserCharge } from '../../pages/UserDetails/types';
import HttpProvider from '../httpProvider';
import { GetChargesForUserWSOutput, GetExpensesFileInput } from './types';

class ChargesProvider {
    private http: HttpProvider;

    constructor(public httpProvider: HttpProvider) {
        this.http = httpProvider;
    }

    getChargesForUser = async (companyId: string, userId: string): Promise<UserCharge[]> =>
        (
            await this.http.get<GetChargesForUserWSOutput>(`/api/v1/companies/${companyId}/users/${userId}/charges`)
        ).charges.map((charge) => ({
            date: charge.date,
            duration: charge.duration,
            id: charge.id,
            siteName: charge.siteName,
            socket: {
                id: charge.socketId,
                name: charge.socketName,
            },
            consumedEnergy: charge.consumedEnergy,
        }));

    getExpensesFileForUser = async (
        companyId: string,
        userId: string,
        month: number,
        year: number,
    ): Promise<{ data: Blob; fileName: string | undefined }> =>
        this.http.postBlob<GetExpensesFileInput>(`/api/v1/companies/${companyId}/users/${userId}/expenses`, {
            month: `${year}-${month}`,
        });

    getExpensesFileForCompany = async (
        companyId: string,
        month: number,
        year: number,
    ): Promise<{ data: Blob; fileName: string | undefined }> =>
        this.http.postBlob<GetExpensesFileInput>(`/api/v1/companies/${companyId}/expenses`, {
            month: `${year}-${month}`,
        });
}

export default ChargesProvider;
