import { Heading, Spinner, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import SocketCard from '../../components/SocketCard/SocketCard';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { Socket } from './types';
import styles from './Sockets.module.css';

const SocketsPage = () => {
    const { t } = useTranslation();
    // const history = useHistory();
    const company = useAppSelector(companySelect);
    const [sockets, setSockets] = useState<Socket[] | undefined>(undefined);

    useEffect(() => {
        setSockets(undefined);
        if (company) {
            providers.socketsProvider.getSockets(company.id).then(setSockets);
        } else {
            setSockets([]);
        }
    }, [company]);

    return (
        <VStack mx={32} my={20}>
            <PageLayoutHeader title={t('sockets.title')} />

            {sockets !== undefined ? (
                <>
                    <Heading key={2} fontWeight={600} mb={6} mt={12} size="sm">
                        {t('sockets.socketsCount', { count: sockets.length })}
                    </Heading>
                    <div key={3} className={styles.grid}>
                        {sockets.map(({ id, name, siteName, chargeStatus, isConnected }) => (
                            <SocketCard
                                key={id}
                                chargeStatus={chargeStatus}
                                isConnected={isConnected}
                                name={name}
                                // onTouch={() => {
                                //     history.push(`/sockets/${id}`);
                                // }}
                                siteName={siteName}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <Spinner size="lg" />
            )}
        </VStack>
    );
};

export default SocketsPage;
