import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Checkbox, Container, FormControl, Text, VStack, WarningOutlineIcon } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { StyledInput } from '../../components/CustomPhoneInput/CustomPhoneInput';
import handleError from '../../components/ErrorToast/ErrorToast';
import GradientButton from '../../components/GradientButton/GradientButton';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import { invokeSuccessToast } from '../../components/Toast/Toast';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { CompanyConfiguration } from './types';

/**
 * Page to change the configuration of the selected company.
 */
const CompanyConfigurationPage = () => {
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);

    const [updatePending, setUpdatePending] = useState(false);
    const [initialPaymentActivated, setInitialPaymentActivated] = useState(false);

    const costPerKWhRegex = /^([0-9\s]+)(,([0-9]{1,2}))?$/g;

    const formSchema = yup.object({
        costPerKWh: yup
            .string()
            .required(t('companyConfiguration.costPerKWh.required'))
            .test(
                'isPriseCorrectlyFormatted',
                t('companyConfiguration.costPerKWh.format'),
                (value) => value !== undefined && costPerKWhRegex.test(value),
            ),
        paymentActivated: yup.boolean(),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<yup.InferType<typeof formSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(formSchema),
        defaultValues: {
            costPerKWh: '',
            paymentActivated: false,
        },
    });

    /**
     * Helper used to convert a CompanyConfiguration to the correct shape for the form.
     * The main idea is to convert the cost per kWh from a float to a correctly-formated string.
     */
    const mapCompanyConfiguration = (companyConfiguration: CompanyConfiguration): yup.InferType<typeof formSchema> => ({
        costPerKWh: new Intl.NumberFormat('fr').format(companyConfiguration.costPerKWh),
        paymentActivated: companyConfiguration.paymentActivated,
    });

    /**
     * Effect run on startup, to get the initial value of company configuration
     */
    useEffect(() => {
        if (company) {
            providers.companiesProvider
                .getCompanyConfiguration(company.id)
                .then((companyConfig) => {
                    reset(mapCompanyConfiguration(companyConfig));
                    setInitialPaymentActivated(companyConfig.paymentActivated);
                })
                .catch(handleError);
        }
    }, [company]);

    const onValidate = handleSubmit((data) => {
        // Special parsing for the price per kWh (from string to float)
        const costPerKWh = parseFloat(data.costPerKWh.replaceAll(/\s/g, '').replaceAll(/,/g, '.'));
        if (company) {
            setUpdatePending(true);
            providers.companiesProvider
                .putCompanyConfiguration(company.id, {
                    costPerKWh,
                    paymentActivated: !!data.paymentActivated,
                })
                .then((companyConfig) => {
                    reset(mapCompanyConfiguration(companyConfig));
                    setInitialPaymentActivated(companyConfig.paymentActivated);
                })
                .then(() => {
                    invokeSuccessToast(
                        t('companyConfiguration.successToast.title'),
                        t('companyConfiguration.successToast.message'),
                    );
                })
                .catch(handleError)
                .finally(() => {
                    setUpdatePending(false);
                });
        }
    });

    const redirectToBeneficiaryAccountLink = (): void => {
        if (company) {
            setUpdatePending(true);
            providers.companiesProvider
                .getBeneficiaryAccountLink(company.id)
                .then((beneficiaryAccountLink) => {
                    window.location.href = beneficiaryAccountLink.url;
                })
                .catch(handleError)
                .finally(() => {
                    setUpdatePending(false);
                });
        }
    };

    return (
        <VStack mx={32} my={20}>
            <PageLayoutHeader title={t('companyConfiguration.title')} />
            <Box bg="secondary.900" borderRadius="10" mt={6} p={6}>
                {t('companyConfiguration.description')}
            </Box>
            <Container>
                <form onSubmit={onValidate}>
                    <FormControl isInvalid={errors.costPerKWh !== undefined} mb="5">
                        <FormControl.Label>{t('companyConfiguration.costPerKWh.label')}</FormControl.Label>
                        <Box maxWidth="300px">
                            <Controller
                                control={control}
                                name="costPerKWh"
                                render={({ field: { onBlur, onChange, ref, value } }) => (
                                    <NumberFormat
                                        ref={ref}
                                        customInput={StyledInput}
                                        decimalSeparator=","
                                        isAllowed={({ value: val }) => {
                                            // We limit the value to values with 2 digits after the comma only.
                                            const splitValue = val.split('.');

                                            return !(
                                                splitValue.length > 1 &&
                                                splitValue[1] &&
                                                splitValue[1].length > 2
                                            );
                                        }}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        placeholder={t('companyConfiguration.costPerKWh.placeholder')}
                                        thousandSeparator=" "
                                        value={value}
                                    />
                                )}
                            />
                        </Box>
                        <FormControl.HelperText>{t('companyConfiguration.costPerKWh.helper')}</FormControl.HelperText>
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                            {errors.costPerKWh?.message}
                        </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl mb="5">
                        <Controller
                            control={control}
                            name="paymentActivated"
                            render={({ field: { onChange, value } }) => (
                                <Checkbox
                                    _text={{
                                        fontSize: 'sm',
                                    }}
                                    isChecked={value}
                                    onChange={onChange}
                                    value="paymentActivated"
                                >
                                    {t('companyConfiguration.paymentActivated.label')}
                                </Checkbox>
                            )}
                        />
                        <FormControl.HelperText>
                            {t('companyConfiguration.paymentActivated.helper')}
                        </FormControl.HelperText>
                    </FormControl>
                    <GradientButton fontSize="sm" isLoading={updatePending} label={t('common.save')} type="submit" />
                </form>
            </Container>
            {initialPaymentActivated && (
                <Container flex="start" mt="5">
                    <Text fontWeight="500" mx="4">
                        {t('companyConfiguration.beneficiaryAccount.title')}
                    </Text>
                    <Text color="trueGray.400" fontSize="xs" mx="4" my="4">
                        {t('companyConfiguration.beneficiaryAccount.description')}
                    </Text>
                    <GradientButton
                        fontSize="sm"
                        isLoading={updatePending}
                        label={t('companyConfiguration.beneficiaryAccount.actionButton')}
                        onClick={() => redirectToBeneficiaryAccountLink()}
                    />
                </Container>
            )}
        </VStack>
    );
};

export default CompanyConfigurationPage;
