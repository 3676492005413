import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import fr from './locales/fr/translation.json';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            fr: {
                translation: fr,
            },
        },
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
