import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import providers from '../../providers';
import { isNetworkException } from '../../providers/types';

/* eslint-disable import/no-cycle */
// cf. https://redux.js.org/usage/usage-with-typescript#define-slice-state-and-action-types ⬇️
import { connect, disconnectThunk } from '../auth/auth';
import { RootState } from '../store';
/* eslint-enable import/no-cycle */
import { LoginErrors, LoginState, LoginSteps, Roles } from './types';

export const initialState: LoginState = { step: LoginSteps.PhoneNumber, loading: false };

export const sendPhoneNumberThunk = createAsyncThunk(
    'login/sendPhoneNumber',
    async (phoneNumber: string, { rejectWithValue }) => {
        try {
            await providers.authProvider.requestOtpCode(phoneNumber);

            return phoneNumber;
        } catch (e) {
            if (isNetworkException(e)) {
                if (e.status === 401) {
                    return rejectWithValue(LoginErrors.NO_ACCESS);
                }

                return rejectWithValue(e.message);
            }

            return rejectWithValue('An error occurred');
        }
    },
);

export const connectThunk = createAsyncThunk(
    'login/connect',
    async (otp: string, { rejectWithValue, dispatch, getState }) => {
        try {
            const loginInfo = await providers.authProvider.login((getState() as RootState).login.phoneNumber!, otp);
            if (loginInfo.roles.includes(Roles.MANAGER)) {
                providers.httpProvider.setInterceptors(loginInfo.token, () => dispatch(disconnectThunk()));
                await dispatch(connect(loginInfo));

                return loginInfo;
            }

            return rejectWithValue(LoginErrors.NO_MANAGER_ROLE);
        } catch (e) {
            if (isNetworkException(e)) {
                if (e.status === 401) {
                    // In this case, the OTP code is not correct.
                    return rejectWithValue(LoginErrors.INCORRECT_CODE);
                }

                return rejectWithValue(e.message);
            }

            return rejectWithValue('An error occurred');
        }
    },
);

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        goToStep: (state, action: PayloadAction<LoginSteps>) => ({ ...state, step: action.payload }),
        deleteError: (state) => ({ ...state, errorMsg: undefined }),
    },
    extraReducers: (builder) => {
        builder.addCase(sendPhoneNumberThunk.pending, (state) => ({
            ...state,
            loading: true,
        }));
        builder.addCase(sendPhoneNumberThunk.fulfilled, (state, { payload }) => ({
            ...state,
            loading: false,
            errorMsg: undefined,
            step: LoginSteps.Otp,
            phoneNumber: payload,
        }));
        builder.addCase(sendPhoneNumberThunk.rejected, (state, { payload }) => ({
            ...state,
            loading: false,
            errorMsg: payload as string,
        }));
        builder.addCase(connectThunk.pending, (state) => ({
            ...state,
            loading: true,
        }));
        builder.addCase(connectThunk.fulfilled, (state) => ({
            ...state,
            step: LoginSteps.PhoneNumber,
            loading: false,
            errorMsg: undefined,
        }));
        builder.addCase(connectThunk.rejected, (state, { payload }) => ({
            ...state,
            loading: false,
            errorMsg: payload as string,
        }));
    },
});

export const errorSelect = (state: RootState) => state.login.errorMsg;
export const isLoadingSelect = (state: RootState) => state.login.loading;
export const loginStepSelect = (state: RootState) => state.login.step;

export const { goToStep, deleteError } = loginSlice.actions;
export default loginSlice.reducer;
