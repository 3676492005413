import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBackIcon, FormControl, IconButton, Row, VStack, WarningOutlineIcon } from 'native-base';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CustomOtpInput } from '../../components/CustomOtpInput/CustomOtpInput';
import GradientButton from '../../components/GradientButton/GradientButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { connectThunk, goToStep, isLoadingSelect } from '../../store/login/login';
import { LoginSteps } from '../../store/login/types';

/**
 * Second part of the Login form, to ask for the OTP code sent to the phone number.
 */
const OtpPart = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(isLoadingSelect);

    const otpSchema = yup.object({
        otp: yup.string().required(t('login.error.otp.required')).length(6, t('login.error.otp.incorrectLength')),
    });

    const {
        handleSubmit: handleSubmitOtp,
        formState: { errors: errorsOtp },
        control: controlOtp,
    } = useForm<yup.InferType<typeof otpSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(otpSchema),
    });

    const onSubmitOtpPressed = handleSubmitOtp(async (data) => {
        await dispatch(connectThunk(data.otp));
    });

    return (
        <form onSubmit={onSubmitOtpPressed}>
            <VStack>
                <FormControl isInvalid={errorsOtp.otp !== undefined} mb={6}>
                    <Row alignItems="center" mb={1}>
                        <IconButton
                            icon={<ArrowBackIcon size="6" />}
                            onPress={() => dispatch(goToStep(LoginSteps.PhoneNumber))}
                            testID="otp-back-button"
                            variant="unstyled"
                        />
                        <FormControl.Label margin={0}>{t('login.enterOtp')}</FormControl.Label>
                    </Row>
                    <Controller
                        control={controlOtp}
                        name="otp"
                        render={({ field }) => <CustomOtpInput onChange={field.onChange} value={field.value} />}
                    />
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                        {errorsOtp.otp?.message}
                    </FormControl.ErrorMessage>
                </FormControl>
                <GradientButton isLoading={isLoading} label={t('common.validate')} type="submit" />
            </VStack>
        </form>
    );
};

export default OtpPart;
