import { Center } from 'native-base';
import React from 'react';
import OtpInput from 'react-otp-input';
import styles from './CustomOtpInput.module.css';

export interface CustomOtpInputProps {
    onChange: (value: ((prevState: string) => string) | string) => void;
    value: string;
}

export const CustomOtpInput = ({ value, onChange }: CustomOtpInputProps) => (
    <Center>
        <OtpInput
            inputStyle={styles.input}
            numInputs={6}
            onChange={onChange}
            value={value}
            isInputNum
            shouldAutoFocus
        />
    </Center>
);
