import { Community } from '../../pages/Users/AddUserModalContent/types';
import HttpProvider from '../httpProvider';
import { GetCommunitiesWSOutput } from './types';

export default class CommunitiesProvider {
    private http: HttpProvider;

    constructor(public httpProvider: HttpProvider) {
        this.http = httpProvider;
    }

    getCommunities = async (companyId: string): Promise<Community[]> =>
        (await this.http.get<GetCommunitiesWSOutput>(`/api/v1/companies/${companyId}/communities`)).communities.map(
            (community) => ({
                id: community.id,
                name: community.name,
            }),
        );
}
