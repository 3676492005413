export type SendOtpWSInput = {
    phoneNumber: string;
};

export type SendOtpWSOutput = {};

export type LoginWSInput = {
    otp: string;
    phonenumber: string;
};

export enum LoginRoleWS {
    CLIENT = 'CLIENT',
    MANAGER = 'MANAGER',
    INSTALLER = 'INSTALLER',
}

export type LoginWSOutput = {
    accessToken: string;
    roles: LoginRoleWS[];
    userId: string;
};
