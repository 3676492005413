import { Socket, SocketChargeStatus } from '../../pages/Sockets/types';
import HttpProvider from '../httpProvider';
import { GetSocketsWSOutput } from './types';

export default class SocketsProvider {
    private http: HttpProvider;

    constructor(public httpProvider: HttpProvider) {
        this.http = httpProvider;
    }

    getSockets = async (companyId: string): Promise<Socket[]> =>
        (await this.http.get<GetSocketsWSOutput>(`/api/v1/companies/${companyId}/sockets`)).sockets.map((socket) => ({
            id: socket.id,
            name: socket.name,
            siteName: socket.siteName,
            chargeStatus: socket.chargeStatus as SocketChargeStatus,
            isConnected: socket.isConnected,
        }));
}
