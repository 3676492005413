import { LoginInfo, Roles } from '../../store/auth/types';
import HttpProvider from '../httpProvider';
import { LoginRoleWS, LoginWSInput, LoginWSOutput, SendOtpWSInput, SendOtpWSOutput } from './types';

class AuthProvider {
    private http: HttpProvider;

    constructor(public httpProvider: HttpProvider) {
        this.http = httpProvider;
    }

    requestOtpCode = async (phoneNumber: string) =>
        this.http.post<SendOtpWSOutput, SendOtpWSInput>('/api/v1/auth/send-otp', { phoneNumber });

    login = async (phoneNumber: string, otp: string): Promise<LoginInfo> => {
        const res = await this.http.post<LoginWSOutput, LoginWSInput>('/api/v1/auth/login', {
            phonenumber: phoneNumber,
            otp,
        });

        return {
            token: res.accessToken,
            userId: res.userId,
            roles: res.roles
                .map((role) => {
                    switch (role) {
                        case LoginRoleWS.MANAGER:
                            return Roles.MANAGER;
                        case LoginRoleWS.INSTALLER:
                            return Roles.INSTALLER;
                        case LoginRoleWS.CLIENT:
                            return Roles.CLIENT;
                        default:
                            return undefined;
                    }
                })
                .filter((value) => value !== undefined) as Roles[],
        };
    };
}

export default AuthProvider;
