import AuthProvider from './authProvider/authProvider';
import ChargesProvider from './chargesProvider/chargesProvider';
import CommunitiesProvider from './communitiesProvider/communitiesProvider';
import CompaniesProvider from './companiesProvider/companiesProvider';
import HttpProvider from './httpProvider';
import SitesProvider from './sitesProvider/sitesProvider';
import SocketsProvider from './socketsProvider/socketsProvider';
import UsersProvider from './usersProvider/usersProvider';

const httpProvider = new HttpProvider();

const providers = {
    httpProvider,
    authProvider: new AuthProvider(httpProvider),
    chargesProvider: new ChargesProvider(httpProvider),
    communitiesProvider: new CommunitiesProvider(httpProvider),
    companiesProvider: new CompaniesProvider(httpProvider),
    socketsProvider: new SocketsProvider(httpProvider),
    sitesProvider: new SitesProvider(httpProvider),
    usersProvider: new UsersProvider(httpProvider),
};

export default providers;
