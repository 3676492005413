import clsx from 'clsx';
import {
    Box,
    CheckIcon,
    ChevronDownIcon,
    Column,
    Heading,
    HStack,
    Icon,
    Image,
    Menu as NBMenu,
    Pressable,
    Skeleton,
    Text,
} from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../pages/Users/types';
import { changeCompany, companySelect } from '../../store/auth/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { profileSelect } from '../../store/profile/profile';
import MenuNavigationItem from './MenuNavigationItem/MenuNavigationItem';
import { ReactComponent as Business } from '../../assets/icons/business.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard_black_24dp.svg';
import { ReactComponent as Sockets } from '../../assets/icons/electrical_services_black_24dp.svg';
import { ReactComponent as Users } from '../../assets/icons/person_black_24dp.svg';
import { ReactComponent as CompanySettings } from '../../assets/icons/settings_black_24dp.svg';
import { ReactComponent as Tools } from '../../assets/icons/tools.svg';
import avatar from '../../assets/images/avatar.png';
import { ReactComponent as Logo } from '../../assets/images/logo-full.svg';

import styles from './Menu.module.css';

export interface MenuProps {
    companies: Company[];
}

const Menu = ({ companies }: MenuProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profile = useAppSelector(profileSelect);
    const company = useAppSelector(companySelect);

    return (
        <Column alignItems="center">
            <Box maxW={32}>
                <Logo width="100%" />
            </Box>
            <Column alignItems="center" mt={12}>
                <Image alt="Avatar" size={16} src={avatar} />
                <Skeleton.Text
                    _line={{ lineHeight: 'sm' }}
                    isLoaded={profile.firstName !== undefined}
                    lines={1}
                    mt={4}
                    py={1}
                >
                    <Heading fontSize="md" fontWeight={600} mt={4}>
                        {profile.firstName}
                    </Heading>
                </Skeleton.Text>

                <Skeleton.Text _line={{ lineHeight: 'sm' }} isLoaded={company !== undefined} lines={1}>
                    <NBMenu
                        placement="bottom"
                        trigger={(triggerProps) => (
                            <Pressable {...triggerProps}>
                                <Heading alignItems="center" display="flex" fontSize="xs" fontWeight={600} mt={2}>
                                    {company?.name}
                                    <div
                                        className={clsx(styles.companySwitchIcon, {
                                            [styles.open]: triggerProps['aria-expanded'],
                                        })}
                                    >
                                        <Icon size={5}>
                                            <ChevronDownIcon />
                                        </Icon>
                                    </div>
                                </Heading>
                            </Pressable>
                        )}
                    >
                        {companies.map((availableCompany) => (
                            <NBMenu.Item
                                key={availableCompany.id}
                                onPress={() => dispatch(changeCompany(availableCompany))}
                            >
                                <HStack alignItems="center" pointerEvents="auto">
                                    <Box mr={2} width={3}>
                                        {availableCompany.id === company?.id ? (
                                            <Icon size={3}>
                                                <CheckIcon />
                                            </Icon>
                                        ) : null}
                                    </Box>
                                    <Text>{availableCompany.name}</Text>
                                </HStack>
                            </NBMenu.Item>
                        ))}
                    </NBMenu>
                </Skeleton.Text>
            </Column>
            <Column mt={8} space={2} width="100%">
                <MenuNavigationItem
                    icon={
                        <Icon size={5}>
                            <Dashboard />
                        </Icon>
                    }
                    name={t('menu.dashboard')}
                    path="/"
                />
                <MenuNavigationItem
                    icon={
                        <Icon size={5}>
                            <Users />
                        </Icon>
                    }
                    name={t('menu.users')}
                    path="/users"
                />
                <MenuNavigationItem
                    icon={
                        <Icon size={5}>
                            <Business />
                        </Icon>
                    }
                    name={t('menu.sites')}
                    path="/sites"
                />
                <MenuNavigationItem
                    icon={
                        <Icon size={5}>
                            <Sockets />
                        </Icon>
                    }
                    name={t('menu.sockets')}
                    path="/sockets"
                />
                <MenuNavigationItem
                    icon={
                        <Icon size={5}>
                            <Tools />
                        </Icon>
                    }
                    name={t('menu.tools')}
                    path="/tools"
                />
                <MenuNavigationItem
                    icon={
                        <Icon size={5}>
                            <CompanySettings />
                        </Icon>
                    }
                    name={t('menu.companyConfiguration')}
                    path="/companySettings"
                />
            </Column>
        </Column>
    );
};

export default Menu;
