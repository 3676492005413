export interface Socket {
    id: string;
    name: string;
    siteName: string;
    chargeStatus: SocketChargeStatus;
    isConnected: boolean;
}

export enum SocketChargeStatus {
    CHARGING = 'CHARGING',
    NOT_CHARGING = 'NOT_CHARGING',
}
