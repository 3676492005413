import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import providers from '../../providers';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';
import { ProfileState } from './types';

const initialState: ProfileState = {};

export const getProfileData = createAsyncThunk('profile/getProfileData', async () =>
    providers.httpProvider.get<{ firstName: string; lastName: string }>('/api/v1/users/me'),
);

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProfileData.fulfilled, (state, action) => ({ ...action.payload }));
    },
});

export const profileSelect = (state: RootState) => state.profile;

export default profileSlice.reducer;
