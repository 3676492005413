import { Box, Flex, Heading, HStack, Text } from 'native-base';
import React, { ReactNode } from 'react';
import { ColumnsDescription, DatumBase } from './types';
import styles from './Table.module.css';

export interface TableProps<T extends DatumBase> {
    title: string;
    asideTitle?: ReactNode;
    noDataLabel: string;
    columns: ColumnsDescription<T>;
    data: T[] | undefined;
}

function Table<T extends DatumBase>({ title, asideTitle, noDataLabel, columns, data }: TableProps<T>) {
    const columnsKeys: (keyof ColumnsDescription<T>)[] = Object.keys(columns) as (keyof ColumnsDescription<T>)[];

    return (
        <Flex flex="1 1" flexDirection="column">
            <HStack alignItems="center" mb={7}>
                <Heading fontSize={19} fontWeight={500} mr={2} size="md">
                    {title}
                </Heading>
                {asideTitle}
            </HStack>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead className={styles.thead}>
                        <tr>
                            {columnsKeys.map((index) => (
                                <th key={index.toString()} className={styles.th}>
                                    <Text fontSize={11} fontWeight={500} lineHeight="1.6em">
                                        {columns[index].name}
                                    </Text>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                        {data !== undefined
                            ? data.map((datum) => (
                                  <tr key={datum.id} data-testid="tr">
                                      {columnsKeys.map((key) => (
                                          <td key={key.toString()} className={styles.td}>
                                              <Text fontSize={14} fontWeight={500}>
                                                  {columns[key].formatData !== undefined
                                                      ? columns[key].formatData!(datum[key], datum)
                                                      : datum[key]}
                                              </Text>
                                          </td>
                                      ))}
                                  </tr>
                              ))
                            : null}
                    </tbody>
                </table>
                {data !== undefined && data.length === 0 ? (
                    <Box alignItems="center" py={6}>
                        <Text>{noDataLabel}</Text>
                    </Box>
                ) : null}
            </div>
        </Flex>
    );
}

export default Table;
