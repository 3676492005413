import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PageLayout from './components/PageLayout/PageLayout';
import RequireAuth from './components/RequireAuth/RequireAuth';
import CompanyConfigurationPage from './pages/CompanyConfiguration/CompanyConfiguration';
import HomePage from './pages/Home/Home';
import LoginPage from './pages/Login/Login';
import SiteDetailsPage from './pages/SiteDetails/SiteDetails';
import SitesPage from './pages/Sites/Sites';
import SocketsPage from './pages/Sockets/Sockets';
import ToolsPage from './pages/Tools/Tools';
import UserDetailsPage from './pages/UserDetails/UserDetails';
import UsersPage from './pages/Users/Users';

import './App.css';

const App = () => (
    <div className="App">
        <Routes>
            <Route path="/">
                <Route element={<LoginPage />} path="login" />
                <Route
                    element={
                        <RequireAuth>
                            <PageLayout />
                        </RequireAuth>
                    }
                    path="/"
                >
                    <Route element={<HomePage />} index />
                    <Route element={<UsersPage />} path="/users" />
                    <Route element={<UserDetailsPage />} path="/users/:id" />
                    <Route element={<SocketsPage />} path="/sockets" />
                    <Route element={<SitesPage />} path="/sites" />
                    <Route element={<SiteDetailsPage />} path="/sites/:id" />
                    <Route element={<ToolsPage />} path="/tools" />
                    <Route element={<CompanyConfigurationPage />} path="/companySettings" />
                </Route>
            </Route>
        </Routes>
        <ToastContainer closeButton={false} position="bottom-right" hideProgressBar />
    </div>
);
export default App;
