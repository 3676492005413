import { Heading, Spinner, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FormModal from '../../components/FormModal/FormModal';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import UserCard from '../../components/UserCard/UserCard';

import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import AddOrEditUserModalContent from './AddUserModalContent/AddOrEditUserModalContent';
import { User } from './types';
import styles from './Users.module.css';

const UsersPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const company = useAppSelector(companySelect);

    const [users, setUsers] = useState<User[] | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState(false);

    const loadUsers = () => {
        if (company) {
            providers.usersProvider.getUsers(company.id).then(setUsers);
        } else {
            setUsers([]);
        }
    };

    useEffect(() => {
        setUsers(undefined);
        loadUsers();
    }, [company]);

    return (
        <VStack mx={32} my={20}>
            <PageLayoutHeader
                asideButtonOnClick={() => setModalOpen(true)}
                asideButtonTitle={t('users.createUserBtn')}
                title={t('users.title')}
            />
            <FormModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                testID="add-user-modal"
                title={t('users.add.title')}
            >
                <AddOrEditUserModalContent
                    onSubmitted={() => {
                        loadUsers();
                        setModalOpen(false);
                    }}
                />
            </FormModal>
            {users !== undefined ? (
                <>
                    <Heading key={2} fontWeight={600} mb={6} mt={12} size="sm">
                        {t('users.usersCount', { count: users.length })}
                    </Heading>
                    <div key={3} className={styles.grid}>
                        {users.map(({ id, chargesCount, communities, firstName, isManager, lastName, status }) => (
                            <UserCard
                                key={id}
                                chargesCount={chargesCount}
                                communities={communities}
                                firstName={firstName}
                                isManager={isManager}
                                lastName={lastName}
                                onTouch={() => {
                                    navigate(`/users/${id}`);
                                }}
                                status={status}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <Spinner size="lg" />
            )}
        </VStack>
    );
};

export default UsersPage;
