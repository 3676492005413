import { Box, Center, Column, Heading, Row, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DashboardTileProps {
    title: string;
    value?: string | number;
    subtitle?: string;
    proportion?: number;
}

const DashboardTile: React.FunctionComponent<DashboardTileProps> = ({ title, value, subtitle, proportion }) => {
    const { t } = useTranslation();

    return (
        <Box bg="secondary.900" borderRadius={10} m={10} minWidth="96" p={6}>
            <Column space={4}>
                <Heading size="sm">{title}</Heading>
                <Center m={6}>
                    <Column alignItems="center">
                        <Row alignItems="baseline">
                            <Heading size="2xl">{value ?? 0}</Heading>
                            {proportion ? (
                                <Heading fontWeight={400} size="sm">
                                    {t('dashboard.proportionFormat', { proportion })}
                                </Heading>
                            ) : null}
                        </Row>
                        <Text>{subtitle}</Text>
                    </Column>
                </Center>
            </Column>
        </Box>
    );
};

export default DashboardTile;
