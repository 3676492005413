import { Badge, Box, Button, Column, Container, Heading, Icon, Image, Row, Spacer, Spinner, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormModal from '../../components/FormModal/FormModal';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import SocketCard from '../../components/SocketCard/SocketCard';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import EditSiteModalContent from '../Sites/EditSiteModalContent/EditSiteModalContent';
import getSiteTypeLabel from '../Sites/utils';
import { SiteDetails } from './types';
import { ReactComponent as Community } from '../../assets/icons/community.svg';

import sitePrivate from '../../assets/images/site-private.svg';
import siteShared from '../../assets/images/site-shared.svg';
import styles from './SiteDetails.module.css';

const SiteDetailsPage = () => {
    const { id: siteId } = useParams<{ id: string }>() as { id: string };
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const [site, setSite] = useState<SiteDetails | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [editModalOpen, setEditModalOpen] = useState(false);

    useEffect(() => {
        if (company !== undefined) {
            providers.sitesProvider
                .getSiteDetails(company.id, siteId)
                .then((siteDetails) => setSite(siteDetails))
                .catch(setError);
        }
    }, [company]);

    const refreshSite = () => {
        if (company !== undefined) {
            providers.sitesProvider
                .getSiteDetails(company.id, siteId)
                .then((siteDetails) => setSite(siteDetails))
                .catch(setError);
        }
    };

    return (
        <Column height="100%" mx={12} py={8}>
            <PageLayoutHeader backUrl="/sites/" />
            {site && (
                <FormModal
                    isOpen={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    testID="edit-site-modal"
                    title={t('siteDetails.edit.title')}
                >
                    <EditSiteModalContent
                        editingSite={site}
                        onSubmitted={() => {
                            refreshSite();
                            setEditModalOpen(false);
                        }}
                    />
                </FormModal>
            )}
            {error !== undefined ? (
                <Heading textAlign="center">{t('common.error.title')}</Heading>
            ) : site !== undefined ? (
                <>
                    <Box
                        key={1}
                        bg="secondary.900"
                        borderRadius={10}
                        flexGrow={0}
                        flexShrink={0}
                        mb={10}
                        px={8}
                        py={5}
                        testID="user-box"
                    >
                        <Row alignItems="center" space={10}>
                            {site.type === 'PRIVATE' ? (
                                <Image alt="Site Private" size={16} src={sitePrivate} />
                            ) : (
                                <Image alt="Site Shared" size={16} src={siteShared} />
                            )}
                            <Container>
                                <Column>
                                    <Row alignItems="center">
                                        <Heading fontSize={25} fontWeight={600}>
                                            {site.name}
                                        </Heading>
                                        {site.communities.length > 0 ? (
                                            <Row ml={6} space={3}>
                                                {site.communities.map((community) => (
                                                    <Badge
                                                        key={community.id}
                                                        _text={{ color: 'white', fontSize: 10 }}
                                                        bg="primary.700"
                                                        color="white"
                                                        flexDirection="row"
                                                        variant="solid"
                                                    >
                                                        <Icon mr={2} size={5}>
                                                            <Community />
                                                        </Icon>
                                                        {community.name}
                                                    </Badge>
                                                ))}
                                            </Row>
                                        ) : null}
                                    </Row>
                                    <Text>{getSiteTypeLabel(t, site.type)}</Text>
                                </Column>
                            </Container>
                            <Spacer />
                            <Button
                                colorScheme="secondary"
                                fontSize="sm"
                                onPress={() => setEditModalOpen(true)}
                                px={5}
                                variant="outline"
                            >
                                {t('siteDetails.edit.button')}
                            </Button>
                        </Row>
                    </Box>
                    <Heading key={2} fontWeight={600} mb={6} mt={12} size="sm">
                        {t('sites.socketsCount', { count: site.sockets.length })}
                    </Heading>
                    <div key={3} className={styles.grid}>
                        {site.sockets.map(({ id, name, chargeStatus, isConnected }) => (
                            <SocketCard
                                key={id}
                                chargeStatus={chargeStatus}
                                isConnected={isConnected}
                                name={name}
                                siteName={site.name}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <Spinner size="lg" />
            )}
        </Column>
    );
};

export default SiteDetailsPage;
