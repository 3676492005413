import { BeneficiaryAccountLink, CompanyConfiguration } from '../../pages/CompanyConfiguration/types';
import { Company } from '../../pages/Users/types';
import HttpProvider from '../httpProvider';
import {
    GetBeneficiaryAccountLinkWSOutput,
    GetCompaniesWSOutput,
    GetCompanyConfigurationWSOutput,
    PutCompanyConfigurationWSInput,
    PutCompanyConfigurationWSOutput,
} from './types';

export default class CompaniesProvider {
    private http: HttpProvider;

    constructor(public httpProvider: HttpProvider) {
        this.http = httpProvider;
    }

    getCompanies = async (): Promise<Company[]> =>
        (await this.http.get<GetCompaniesWSOutput>('/api/v1/companies')).companies;

    getCompanyConfiguration = async (companyId: string): Promise<CompanyConfiguration> => {
        const companyConfigWs = await this.http.get<GetCompanyConfigurationWSOutput>(
            `/api/v1/companies/${companyId}/configuration`,
        );

        return {
            costPerKWh: companyConfigWs.costPerKWh,
            paymentActivated: companyConfigWs.paymentActivated,
        };
    };

    putCompanyConfiguration = async (
        companyId: string,
        configuration: CompanyConfiguration,
    ): Promise<CompanyConfiguration> => {
        const companyConfigWs = await this.http.put<PutCompanyConfigurationWSOutput, PutCompanyConfigurationWSInput>(
            `/api/v1/companies/${companyId}/configuration`,
            {
                costPerKWh: configuration.costPerKWh,
                paymentActivated: configuration.paymentActivated,
            },
        );

        return {
            costPerKWh: companyConfigWs.costPerKWh,
            paymentActivated: companyConfigWs.paymentActivated,
        };
    };

    getBeneficiaryAccountLink = async (companyId: string): Promise<BeneficiaryAccountLink> => {
        const beneficiaryAccountLink = await this.http.get<GetBeneficiaryAccountLinkWSOutput>(
            `/api/v1/companies/${companyId}/beneficiary-account-link`,
        );

        return {
            url: beneficiaryAccountLink.url,
        };
    };
}
