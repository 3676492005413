import { SiteType } from '../types';

export interface Community {
    id: string;
    name: string;
}

export interface EditSitePayload {
    id: string;
    communities: string[] | undefined;
    name: string;
    type: SiteType;
}

export class SiteNameAlreadyExistsException {
    name: string;

    constructor(name: string) {
        this.name = name;
    }
}
