import { Company } from '../../pages/Users/types';

export interface AuthState {
    roles?: string[];
    token?: string;
    userId?: string;
    company?: Company;
}

export enum Roles {
    CLIENT = 'CLIENT',
    MANAGER = 'MANAGER',
    INSTALLER = 'INSTALLER',
}

export interface LoginInfo {
    token: string;
    userId: string;
    roles: Roles[];
}

export type GetCompanyPayload = {
    selectedCompany: Company;
    companies: Company[];
};

export const isGetCompanyPayload = (obj: any): obj is GetCompanyPayload =>
    (obj as GetCompanyPayload).selectedCompany !== undefined && (obj as GetCompanyPayload).companies !== undefined;
