import { Heading, HStack, Icon, IconButton, Row, Spinner, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import providers from '../../providers';
import { companySelect, disconnectThunk } from '../../store/auth/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import DashboardTile from './DashboardTile/DashboardTile';
import { DashboardData, DashboardWSOutput } from './types';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout_black_24dp.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh_black_24dp.svg';

/**
 * Build a string representing a duration in a comprehensive format.
 * @param minuteNb The number of minutes.
 */
const humanizeDuration = (minuteNb: number) => {
    const hours = Math.trunc(minuteNb / 60);
    const minutes = minuteNb % 60;

    return `${hours}h${minutes}`;
};

const HomePage = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<DashboardData | undefined>(undefined);
    const dispatch = useAppDispatch();
    const company = useAppSelector(companySelect);

    const fetchData = () => {
        providers.httpProvider
            .get<DashboardWSOutput>(`/api/v1/manager/${company?.id}/dashboard`)
            .then((res) =>
                setData({
                    siteNb: res.sitesCount,
                    sockets: {
                        inCharge: res.socketsInChargeCount,
                        total: res.socketsCount,
                    },
                    userNb: res.usersCount,
                    monthlyChargeDuration: res.monthlyChargeDuration,
                }),
            )
            .catch(console.warn);
    };

    useEffect(() => {
        if (company) {
            fetchData();
        }
    }, [company]);

    return (
        <VStack m={8}>
            <HStack mb={10}>
                <Heading flex={1} size="lg">
                    {t('dashboard.title')}
                </Heading>
                <Row alignItems="center" space={3}>
                    <IconButton
                        borderRadius="full"
                        icon={
                            <Icon>
                                <RefreshIcon />
                            </Icon>
                        }
                        onPress={fetchData}
                    />
                    <IconButton
                        borderRadius="full"
                        icon={
                            <Icon>
                                <LogoutIcon />
                            </Icon>
                        }
                        onPress={() => {
                            dispatch(disconnectThunk());
                        }}
                    />
                </Row>
            </HStack>
            <Row flexWrap="wrap" justifyContent="center">
                {data ? (
                    <>
                        <DashboardTile
                            subtitle={t('dashboard.siteNumber.subtitle', { count: data.siteNb })}
                            title={t('dashboard.siteNumber.title')}
                            value={data.siteNb}
                        />
                        <DashboardTile
                            proportion={data.sockets.total}
                            subtitle={t('dashboard.socketNumber.subtitle', { count: data.sockets.inCharge })}
                            title={t('dashboard.socketNumber.title')}
                            value={data.sockets.inCharge}
                        />
                        <DashboardTile
                            subtitle={t('dashboard.userNumber.subtitle', { count: data.userNb })}
                            title={t('dashboard.userNumber.title')}
                            value={data.userNb}
                        />
                        <DashboardTile
                            subtitle={t('dashboard.chargeDuration.subtitle')}
                            title={t('dashboard.chargeDuration.title')}
                            value={humanizeDuration(data.monthlyChargeDuration)}
                        />
                    </>
                ) : (
                    <Spinner size="lg" />
                )}
            </Row>
        </VStack>
    );
};

export default HomePage;
