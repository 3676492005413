import {
    Badge,
    Box,
    Button,
    Column,
    Container,
    Heading,
    Icon,
    IconButton,
    Image,
    Row,
    Spacer,
    Spinner,
    Text,
    Tooltip,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router-dom';
import Duration from '../../components/Duration/Duration';
import FormModal from '../../components/FormModal/FormModal';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import Table from '../../components/Table/Table';
import { ColumnsDescription } from '../../components/Table/types';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import AddOrEditUserModalContent from '../Users/AddUserModalContent/AddOrEditUserModalContent';
import DeleteUserModalContent from '../Users/DeleteUserModalContent/DeleteUserModalContent';
import getStatusLabel from '../Users/utils';
import ExpenseUserMonthSelection from './ExpenseUserMonthSelection/ExpenseUserMonthSelection';
import { UserCharge, UserDetails } from './types';
import { ReactComponent as Community } from '../../assets/icons/community.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_black_24dp.svg';
import { ReactComponent as RequestQuoteIcon } from '../../assets/icons/request_quote.svg';
import avatar from '../../assets/images/user-2x.png';

const UserDetailsPage = () => {
    const { id } = useParams<{ id: string }>() as { id: string };
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const [user, setUser] = useState<UserDetails | undefined>(undefined);
    const [charges, setCharges] = useState<UserCharge[] | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [expenseMonthSelectionModalOpen, setExpenseMonthSelectionModalOpen] = useState(false);
    const navigate = useNavigate();

    const columnsDescription: ColumnsDescription<UserCharge> = {
        siteName: {
            id: 'siteName',
            name: t('userDetails.columns.site'),
        },
        socket: {
            id: 'socket',
            name: t('userDetails.columns.socket'),
            formatData: (value) => value.name,
        },
        duration: {
            id: 'duration',
            name: t('userDetails.columns.duration'),
            formatData: (value) => <Duration duration={value} />,
        },
        date: {
            id: 'date',
            name: t('userDetails.columns.date'),
            formatData: (value) =>
                t('common.template.date', {
                    val: new Date(value),
                }),
        },
        consumedEnergy: {
            id: 'consumedEnergy',
            name: t('userDetails.columns.consumedEnergy'),
            formatData: (value) => t('common.units.kWh', { val: value }),
        },
    };

    useEffect(() => {
        if (company !== undefined) {
            Promise.all([
                providers.usersProvider.getUserDetails(company.id, id),
                providers.chargesProvider.getChargesForUser(company.id, id),
            ])
                .then(([userDetails, userCharges]) => {
                    setUser(userDetails);
                    setCharges(userCharges);
                })
                .catch(setError);
        }
    }, [company]);

    const refreshUser = () => {
        if (company !== undefined) {
            providers.usersProvider
                .getUserDetails(company.id, id)
                .then((userDetails) => setUser(userDetails))
                .catch(setError);
        }
    };

    return (
        <Column height="100%" mx={12} py={8}>
            <PageLayoutHeader backUrl="/users/" />
            <FormModal
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                testID="edit-user-modal"
                title={t('userDetails.edit.title')}
            >
                <AddOrEditUserModalContent
                    editingUser={user}
                    onSubmitted={() => {
                        refreshUser();
                        setEditModalOpen(false);
                    }}
                />
            </FormModal>
            <FormModal
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                testID="delete-user-modal"
                title={t('userDetails.delete.title')}
            >
                <DeleteUserModalContent
                    onSubmitted={() => {
                        navigate(`/users`);
                        setDeleteModalOpen(false);
                    }}
                    user={user}
                />
            </FormModal>
            {user !== undefined && company !== undefined ? (
                <FormModal
                    isOpen={expenseMonthSelectionModalOpen}
                    onClose={() => setExpenseMonthSelectionModalOpen(false)}
                    testID="expenses-month-modal"
                    title={t('userDetails.expenses.title')}
                >
                    <ExpenseUserMonthSelection
                        closeModal={() => setExpenseMonthSelectionModalOpen(false)}
                        company={company}
                        user={user}
                    />
                </FormModal>
            ) : null}
            {error !== undefined ? (
                <Heading textAlign="center">{t('common.error.title')}</Heading>
            ) : user !== undefined && charges !== undefined ? (
                <>
                    <Box
                        key={1}
                        bg="secondary.900"
                        borderRadius={10}
                        flexGrow={0}
                        flexShrink={0}
                        mb={10}
                        px={8}
                        py={5}
                        testID="user-box"
                    >
                        <Row alignItems="center" space={10}>
                            <Image alt="User" ml={3} size={24} src={avatar} />
                            <Container>
                                <Column>
                                    <Badge bg="teal.500" mb={1} width={16}>
                                        {getStatusLabel(t, user.status)}
                                    </Badge>
                                    <Row alignItems="center">
                                        <Heading
                                            fontSize={25}
                                            fontWeight={600}
                                        >{`${user.firstName} ${user.lastName}`}</Heading>
                                        {user.communities.length > 0 ? (
                                            <Row ml={6} space={3}>
                                                {user.communities.map((community) => (
                                                    <Badge
                                                        key={community.id}
                                                        _text={{ color: 'white', fontSize: 10 }}
                                                        bg="primary.700"
                                                        color="white"
                                                        flexDirection="row"
                                                        variant="solid"
                                                    >
                                                        <Icon mr={2} size={5}>
                                                            <Community />
                                                        </Icon>
                                                        {community.name}
                                                    </Badge>
                                                ))}
                                            </Row>
                                        ) : null}
                                    </Row>
                                    <Text>{formatPhoneNumber(user.phoneNumber)}</Text>
                                </Column>
                            </Container>
                            <Spacer />
                            <Button
                                colorScheme="secondary"
                                fontSize="sm"
                                onPress={() => setEditModalOpen(true)}
                                px={5}
                                variant="outline"
                            >
                                {t('userDetails.edit.button')}
                            </Button>
                            <IconButton
                                icon={
                                    <Icon>
                                        <DeleteIcon />
                                    </Icon>
                                }
                                onPress={() => setDeleteModalOpen(true)}
                                testID="delete-user-button"
                                variant="unstyled"
                            />
                        </Row>
                    </Box>
                    <Box key={2} flex="1 1">
                        <Table
                            asideTitle={
                                <Tooltip label={t('userDetails.expenses.tooltip')}>
                                    <IconButton
                                        icon={
                                            <Icon size={6}>
                                                <RequestQuoteIcon />
                                            </Icon>
                                        }
                                        onPress={() => setExpenseMonthSelectionModalOpen(true)}
                                        testID="request-note-icon"
                                        variant="unstyled"
                                    />
                                </Tooltip>
                            }
                            columns={columnsDescription}
                            data={charges}
                            noDataLabel={t('userDetails.table.noDataLabel')}
                            title={t('userDetails.table.title', { nb: charges.length })}
                        />
                    </Box>
                </>
            ) : (
                <Spinner size="lg" />
            )}
        </Column>
    );
};

export default UserDetailsPage;
