import { Badge, Column, Image, Pressable, Row, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getStatusLabel from '../../pages/Users/utils';
import user from '../../assets/images/user.png';

interface UserCardProps {
    chargesCount: number;
    communities: string[];
    firstName: string;
    isManager: boolean;
    lastName: string;
    onTouch?: VoidFunction;
    status: 'ACTIVE';
}

const UserCard = ({ chargesCount, communities, firstName, isManager, lastName, onTouch, status }: UserCardProps) => {
    const { t } = useTranslation();

    return (
        <Pressable
            _hover={{ bg: 'secondary.800' }}
            bg="secondary.900"
            borderRadius={10}
            disabled={onTouch === undefined}
        >
            <div onClick={onTouch} onKeyPress={onTouch} role="gridcell" tabIndex={-1}>
                <Column m={3}>
                    <Row justifyContent="end">
                        <Badge bg="teal.500" width={16}>
                            {getStatusLabel(t, status)}
                        </Badge>
                    </Row>
                    <Row alignItems="center" mb={3} ml={3}>
                        <Image alt="User" size={16} src={user} />
                        <Column pl={3}>
                            <Text fontSize="sm" fontWeight="600">
                                {`${firstName} ${lastName}`}
                            </Text>
                            <Text fontSize="xs">
                                {`${
                                    communities.length === 1
                                        ? communities[0]
                                        : t('users.card.communitiesCount', { count: communities.length })
                                } ${isManager ? `• ${t('users.card.manager')}` : ''}`}
                            </Text>
                            <Text fontSize="xs">{t('users.card.chargesCount', { count: chargesCount })}</Text>
                        </Column>
                    </Row>
                </Column>
            </div>
        </Pressable>
    );
};

export default UserCard;
