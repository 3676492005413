import { Box, Modal, Text } from 'native-base';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import handleError from '../../../components/ErrorToast/ErrorToast';
import GradientButton from '../../../components/GradientButton/GradientButton';
import MonthSelector from '../../../components/MonthSelector/MonthSelector';
import { invokeInfoToast, invokeSuccessToast } from '../../../components/Toast/Toast';
import YearSelector from '../../../components/YearSelector/YearSelector';
import providers from '../../../providers';
import { isNetworkException } from '../../../providers/types';
import { Company } from '../../Users/types';
import { UserDetails } from '../types';

interface ExpenseUserMonthSelectionProps {
    company: Company;
    user: UserDetails;
    closeModal: () => void;
}

const ExpenseUserMonthSelection = ({ closeModal, user, company }: ExpenseUserMonthSelectionProps) => {
    const [month, setMonth] = useState<number>(new Date().getMonth());
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const downloadedFileHref = useRef<HTMLAnchorElement>(null);

    const onValidate = async () => {
        try {
            setIsLoading(true);
            const { data: blob, fileName } = await providers.chargesProvider.getExpensesFileForUser(
                company.id,
                user.id,
                month + 1,
                year,
            );

            const href = window.URL.createObjectURL(blob);
            const a = downloadedFileHref.current;
            if (a) {
                a.download = fileName ?? 'expenses-note.xlsx';
                a.href = href;
                a.click();
                a.href = '';
                invokeSuccessToast(t('expenses.success.title'), t('expenses.success.description'));
                closeModal();
            } else {
                throw new Error();
            }
        } catch (e) {
            if (isNetworkException(e) && e.status === 204) {
                // No suitable charges for this month.
                // We display an info toast.
                invokeInfoToast(t('expenses.noExpenses.title'), t('expenses.noExpenses.description'));
            } else if (e instanceof Error) {
                handleError(e);
            } else {
                console.error(e);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal.Body>
                <Text mb={4} w="full">{`${t('userDetails.expenses.subtitle')}`}</Text>
                <YearSelector setYear={setYear} year={year} />
                <Box bg="gray.400" h="1px" mb={6} w="full" />
                <Box my={4}>
                    <MonthSelector month={month} setMonth={setMonth} year={year} />
                </Box>
            </Modal.Body>
            <Modal.Footer>
                <GradientButton
                    fontSize="sm"
                    isLoading={isLoading}
                    label={t('userDetails.expenses.validate')}
                    onClick={onValidate}
                />
            </Modal.Footer>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label,jsx-a11y/anchor-is-valid */}
            <a ref={downloadedFileHref} />
        </>
    );
};

export default ExpenseUserMonthSelection;
