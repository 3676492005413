import { Input } from 'native-base';
import React, { ChangeEvent } from 'react';
import PhoneInput from 'react-phone-number-input/input';

export const StyledRefInput = React.forwardRef<
    HTMLInputElement,
    { value?: string; onChange?: (value: string | undefined) => void | undefined; placeholder: string }
>((props, ref) => (
    <Input
        ref={ref}
        onChange={(e) => props.onChange?.(e.nativeEvent.text)}
        placeholder={props.placeholder}
        type="text"
        value={props.value}
        variant="rounded"
    />
));

export interface CustomPhoneInputProps {
    value: string | undefined;
    onChange: (value: string | undefined) => void;
    placeholder?: string;
}

export const CustomPhoneInput = ({ value, onChange, placeholder }: CustomPhoneInputProps) => (
    <PhoneInput
        country="FR"
        inputComponent={StyledRefInput as any}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
    />
);

export const StyledInput = ({
    value,
    onChange,
    placeholder,
}: Pick<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'placeholder'>) => (
    <Input
        onChange={(e) => {
            onChange?.(e as unknown as ChangeEvent<HTMLInputElement>);
        }}
        placeholder={placeholder}
        type="text"
        value={value?.toString()}
        variant="rounded"
    />
);
