import { Box, ITextProps, Spinner, useTheme } from 'native-base';
import React from 'react';
import styles from './GradientButton.module.css';

/* eslint-disable react/button-has-type */

export interface GradientButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    isLoading?: boolean;
    label: string;
    onClick?: VoidFunction;
    fontSize?: ITextProps['fontSize'];
}

const GradientButton = ({ label, isLoading, onClick, fontSize = 'md', type = 'button' }: GradientButtonProps) => {
    const {
        colors: { primary },
    } = useTheme();

    return (
        <button
            className={styles.container}
            onClick={isLoading ? undefined : onClick}
            style={{ background: `linear-gradient(60.31deg, ${primary['700']} 6.21%, ${primary['300']} 100%)` }}
            type={type}
        >
            <Box
                _text={{
                    fontSize,
                    fontWeight: 'medium',
                    textAlign: 'center',
                }}
                m={2}
                px={4}
            >
                {isLoading ? <Spinner size="sm" /> : label}
            </Box>
        </button>
    );
};

export default GradientButton;
