import { Column, Image, Pressable, Row, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteType } from '../../pages/Sites/types';
import getSiteTypeLabel from '../../pages/Sites/utils';
import sitePrivate from '../../assets/images/site-private.svg';
import siteShared from '../../assets/images/site-shared.svg';

interface SiteCardProps {
    name: string;
    type: SiteType;
    communities: string[];
    onTouch?: VoidFunction;
}

const SiteCard = ({ name, type, communities, onTouch }: SiteCardProps) => {
    const { t } = useTranslation();

    return (
        <Pressable
            _hover={{ bg: 'secondary.800' }}
            bg="secondary.900"
            borderRadius={10}
            disabled={onTouch === undefined}
        >
            <div onClick={onTouch} onKeyPress={onTouch} role="gridcell" tabIndex={-1}>
                <Column m={3}>
                    <Row alignItems="center" m={3}>
                        {type === 'PRIVATE' ? (
                            <Image alt="Site Private" size={16} src={sitePrivate} />
                        ) : (
                            <Image alt="Site Shared" size={16} src={siteShared} />
                        )}

                        <Column pl={3}>
                            <Text fontSize="sm" fontWeight="600">
                                {name}
                            </Text>
                            <Text fontSize="xs">{getSiteTypeLabel(t, type)}</Text>
                            <Text fontSize="xs">
                                {communities.length === 1
                                    ? communities[0]
                                    : t('users.card.communitiesCount', { count: communities.length })}
                            </Text>
                        </Column>
                    </Row>
                </Column>
            </div>
        </Pressable>
    );
};

export default SiteCard;
