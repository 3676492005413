import axios, { AxiosInstance } from 'axios';
import { isAxiosError, NetworkException } from './types';

export const baseUrl = () => process.env.REACT_APP_DOMAIN ?? undefined;

export default class HttpProvider {
    private axiosInstance: AxiosInstance;

    private injectTokenInterceptor: number | undefined;

    private dispatchDisconnectInterceptor: number | undefined;

    constructor() {
        // The URL of the back-end is "auto-injected" by CRA when in production, because the front-end is located at the same place as the back-end.
        // But when in development, we need to inject the URL by hand, and we use an env var for that (see file `.env.development`).
        this.axiosInstance = axios.create({
            baseURL: baseUrl(),
        });
    }

    setInterceptors = (token: string, disconnect: VoidFunction) => {
        // Adding an interceptor to inject the authentication token on outgoing requests
        this.injectTokenInterceptor = this.axiosInstance.interceptors.request.use((config) => {
            if (config.headers === undefined) {
                // eslint-disable-next-line no-param-reassign
                config.headers = {};
            }
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`;

            return config;
        });

        // Adding an interceptor to handle the case when the response is a 401 Unauthorized
        this.dispatchDisconnectInterceptor = this.axiosInstance.interceptors.response.use(undefined, (error) => {
            if (axios.isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 403)) {
                // TODO PRE 2022-03-29 See to add a message to display to users, to say they were disconnected from the app (because of not connected or not the Manager access).
                disconnect();
            }

            return Promise.reject(error);
        });
    };

    removeInterceptors = () => {
        if (this.injectTokenInterceptor !== undefined) {
            this.axiosInstance.interceptors.request.eject(this.injectTokenInterceptor);
        }

        if (this.dispatchDisconnectInterceptor !== undefined) {
            this.axiosInstance.interceptors.response.eject(this.dispatchDisconnectInterceptor);
        }
    };

    get = async <T>(path: string): Promise<T> => {
        try {
            const { data } = await this.axiosInstance.get<T>(path);

            return data;
        } catch (e) {
            if (isAxiosError(e)) {
                throw new NetworkException(e.response?.status, e.message, e.response?.data);
            } else {
                throw e;
            }
        }
    };

    post = async <T, K>(path: string, payload: K, returnAsBlob = false): Promise<T> => {
        try {
            const { data } = await this.axiosInstance.post(path, payload, {
                responseType: returnAsBlob ? 'blob' : 'json',
            });

            return data;
        } catch (e) {
            if (isAxiosError(e)) {
                const businessErrorMessage: string | undefined = e.response?.data.message?.join(' / ');
                throw new NetworkException(
                    e.response?.status,
                    `${e.message} : ${businessErrorMessage}`,
                    e.response?.data,
                );
            } else {
                throw e;
            }
        }
    };

    postBlob = async <K>(path: string, payload: K): Promise<{ data: Blob; fileName: string | undefined }> => {
        try {
            const { data, status, headers } = await this.axiosInstance.post(path, payload, {
                responseType: 'blob',
            });

            if (status === 204) {
                throw new NetworkException(status, 'No content', undefined);
            }

            return { data, fileName: headers['x-suggested-filename'] };
        } catch (e) {
            if (isAxiosError(e)) {
                const businessErrorMessage: string | undefined = e.response?.data.message?.join(' / ');
                throw new NetworkException(
                    e.response?.status,
                    `${e.message} : ${businessErrorMessage}`,
                    e.response?.data,
                );
            } else {
                throw e;
            }
        }
    };

    put = async <T, K>(path: string, payload: K): Promise<T> => {
        try {
            const { data } = await this.axiosInstance.put(path, payload);

            return data;
        } catch (e) {
            if (isAxiosError(e)) {
                const businessErrorMessage: string | undefined = e.response?.data.message?.join(' / ');
                throw new NetworkException(
                    e.response?.status,
                    `${e.message} : ${businessErrorMessage}`,
                    e.response?.data,
                );
            } else {
                throw e;
            }
        }
    };
}
